import React from 'react'
import { Link } from 'react-router-dom'
import Slideone from '../../assets/images/main-slider/Slider-Pause-Cafe-1.png'
import Slidetwo from '../../assets/images/main-slider/Slider-Pause-Cafe-2.png'
import Slidethree from '../../assets/images/main-slider/Slider-Pause-Cafe-3.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import bookicon1 from '../../assets/images/resource/book-icon-pausecafe.png'


function Banner() {
    return (
        <>

            <Swiper className="banner-section"
                loop={true}
                spaceBetween={50}
                slidesPerView={1} 
                navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                  }}>

                <div className="banner-container">
                    <div className="banner-slider" >
                        <div className="swiper-wrapper">

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">

                                    <div className="image-layer" style={{ backgroundImage: `url(${Slideone})` }}></div>

                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>delightful experience</span></div>

                                                        <div className="pattern-image">

                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                                                        </div>


                                                        <h1><span>ยินดีต้อนรับเข้าสู่<br />PUSECAFE MENU</span></h1>
                                                        <div className="text">อาหาร&เครื่องดื่ม ครอบครัว&เพื่อนฟูง</div>
                                                        <div className="links-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                            <div className="link">
                                                                <Link to="/menu" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">เลือกดูเมนูของเรา</span>
                                                                        <span className="text-two">เลือกดูเมนูของเรา</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Slidetwo})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>mouthwatering food</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span>อาหารรสเลิศ<br />รังสรรค์ด้วยความประณีต</span></h1>
                                                        <div className="text">โดยพ่อครัว&แม่ครัวเทรนให้ได้มาตรฐาน</div>
                                                        <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">เลือกดูเมนูของเรา</span>
                                                                        <span className="text-two">เลือกดูเมนูของเรา</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide slide-item">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Slidethree})` }}></div>
                                    <div className="auto-container">
                                        <div className="content-box">
                                            <div className="content">
                                                <div className="clearfix">
                                                    <div className="inner">
                                                        <div className="subtitle"><span>refreshing drinks</span></div>
                                                        <div className="pattern-image">
                                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                                        </div>
                                                        <h1><span>เครื่องดื่มสดชื่น <br />เบาหรือหนักคุณเลือกได้</span></h1>
                                                        <div className="text">โดยบาร์เทนเดอร์จากทางร้าน</div>
                                                        <div className="links-box clearfix">
                                                            <div className="link">
                                                                <Link to="#" className="theme-btn btn-style-two clearfix">
                                                                    <span className="btn-wrap">
                                                                        <span className="text-one">เลือกดูเมนูของเรา</span>
                                                                        <span className="text-two">เลือกดูเมนูของเรา</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                            
                        </div>

                        <div className="swiper-button-prev"><span className="fal fa-angle-left"></span></div>
                        <div className="swiper-button-next"><span className="fal fa-angle-right"></span></div>
       

                    </div>
                </div>

                <div className="book-btn">
  <a href="https://m.me/110791831815834" target="_blank" rel="noreferrer" className="theme-btn"> 
    <span className="icon">
      <img src={bookicon1} alt="" title="" /> 
    </span>
    <span className="txt">สำรองจองโต๊ะ</span>
  </a>
</div>
                
            </Swiper>

        </>
    )
}

export default Banner