import React from 'react';
import Backgroundimage from '../../assets/images/main-slider/Slider-Pause-Cafe-1.png';
import { Link } from 'react-router-dom'
//import logonew from '../../assets/images/logo.png'


function Main() {
    return (
        <>

            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        
                                        <div className="info">
                                            <ul>
                                                <li>พอส คาเฟ่ ทางหลวงหมายเลข 225, เลขที่ 309/9, หมู่ที่ 5,</li>
                                                <li> ตำบล ชีลอง, อำเภอ เมือง, จังหวัด ชัยภูมิ 36000, ประเทศไทย</li>
                                                <li><a href="https://m.me/110791831815834" target="_blank" rel="noreferrer"><i className="icon far fa-envelope"></i>  ติดต่อเราได้ที่แฟนเพจ Pause Cafe</a></li>
                                                <li><a href="tel:+66928351562"><i className="icon far fa-phone"></i>  +66 92 835 1562 </a></li>
                                                <li>เปิดให้บริการ : 18.00 น. - 24:00 น.</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                            <h3>  </h3>
                                            <div className="text"> <span>  </span></div>
                                            <div className="newsletter-form">
                                                
                                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">หน้าแรก</Link></li> 
                                        <li> <Link to="/menu">เมนู</Link></li>
                                        <li><a href="https://m.me/110791831815834" target="_blank" rel="noreferrer">ติดต่อสอบถาม</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <a href="https://www.facebook.com/profile.php?id=100086758152148" target="_blank" rel="noreferrer">facebook</a></li>
                                        <li><a href="https://maps.app.goo.gl/sTdZnAhdJgHxxiQh8?g_st=ic" target="_blank" rel="noreferrer">Google Maps</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy;POSCHTUNER. All Rights Reserved   |    Crafted by <Link to="https://www.facebook.com/Vitcharat.Four/" target="blank">Vicharat Permcheelong</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>

        </>

    )
}

export default Main