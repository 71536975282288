import React from 'react'
//import Videosection from '../Menhome/Videosection'
//import Special from '../Menhome/Special'
//import Contact from '../Menhome/Contact'
import Banner from '../Menhome/Banner'
//import Offer from '../Menhome/Offer'
//import Story from '../Menhome/Story'
//import Specialdish from '../Menhome/Specialdish'
//import Menusection from '../Menhome/Menusection'
//import Whyus from '../Menhome/Whyus'
//import Teem from '../Menhome/Teem'
//import Reacntupdate from '../Menhome/Recntupdates'
//import { Link } from 'react-router-dom'
//import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'



function Main() {
    return (

        <>

            <Banner />
            
           

        </>

    )
}

export default Main