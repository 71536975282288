import React from 'react'
import { Link } from 'react-router-dom'
//import bgone from '../../../assets/images/background/bg-16.png'
//import bgtwo from '../../../assets/images/background/bg-17.png'
//import bgthree from '../../../assets/images/background/bg-18.png'
import menuone from '../../../assets/images/resource/menu1.png'
import menutwo from '../../../assets/images/resource/menu.png'
import menuthree from '../../../assets/images/resource/menu2.png'
import menufour from '../../../assets/images/resource/menu3.png'
import Backgroundimage from '../../../assets/images/background/image-8.jpg';
import img8 from '../../../assets/images/background/Pro-300867.png'
import img9 from '../../../assets/images/background/Pro-set-food-a.png'
import img10 from '../../../assets/images/background/Pro-set-food-b.png'
import img11 from '../../../assets/images/background/Pro-set-food-c.png'
import img12 from '../../../assets/images/background/Pro-set-food-d.png'
import img13 from '../../../assets/images/background/Pro-set-drink-a.png'
import img14 from '../../../assets/images/background/Pro-set-drink-b.png'
import img15 from '../../../assets/images/background/Pro-set-drink-c.png'
import badgeone from '../../../assets/images/resource/badge-2.png'

function Startermenu() {
    return (

        <>

         <section className="chef-selection">
         
                <div className="auto-container">
                <div className="title-box centered">

                        <div className="subtitle"><span>Special Promotion!</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>โปรโมชั่นพิเศษ!</h2>
                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img8} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Special Promotion!</span></div>
                                        <div className="subtitle"><span>30/08/67</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>3 แถม 1 และ 3 แถม 2 (ลีโอ)</h3>
                                        <div className="text">พิเศษ! สำหรับกิจกรรมสุดพิเศษจากพี่เสือใจดี 🐯 โปรโมชั่นลีโอ 3 แถม 1 และ 3 แถม 2 </div>
                                        <div className="text">ฟรี! น้ำแข็ง 1 ถัง! ในราคาพิเศษ!</div>
                                    </div>
                                    <div className="text">🐯 โปรโมชั่นลีโอ 3 แถม 1 </div><div className="price"><span className="old">283.-</span> <span className="new">199.-</span></div>
                                    <div className="text">🐯 โปรโมชั่นลีโอ 3 แถม 2 </div><div className="price"><span className="old">323.-</span> <span className="new">259.-</span></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section className="menu-one">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Menu</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูอาหาร</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menuone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูยำ</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำทะเล</Link></h5></div><div className="price"><span>149.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำถั่วพู</Link><span className="s-info">แนะนำ</span></h5></div><div className="price"><span>129.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำหมูยอ</Link></h5></div><div className="price"><span>89.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำวุ้นเส้น หมูสับ/ทะเล</Link></h5></div><div className="price"><span>79.-/129.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">หมูมะนาว</Link></h5></div><div className="price"><span>99.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำถั่ว</Link></h5></div><div className="price"><span>89.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ยำเม็ดมะม่วงหิมพานต์</Link></h5></div><div className="price"><span>119.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">กุ้งแช่</Link><span className="s-info">แนะนำ</span></h5></div><div className="price"><span>129.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูต้ม</h2></span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ต้มยำรวมมิตร</Link><span className="s-info">แนะนำ</span></h5></div><div className="price"><span>149.-/179.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>น้ำข้น/น้ำใส (ถ้วย/หม้อไฟ)</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ต้มยำ</Link></h5></div><div className="price"><span>149.-/179.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>ปลา/หมึก/กุ้ง/ทะเล (ถ้วย/หม้อไฟ)</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ต้มยำไก่</Link></h5></div><div className="price"><span>129.-/159.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>(ถ้วย/หม้อไฟ)</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ต้มยำไก่</Link></h5></div><div className="price"><span>129.-/159.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>(ถ้วย/หม้อไฟ)</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">รวมมิตรมาม่าหม้อไฟ</Link></h5></div><div className="price"><span>199.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูทอด</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เอ็ดไก่ทอด</Link></h5></div><div className="price"><span>119.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ปีกไก่ทอดน้ำปลา</Link></h5></div><div className="price"><span>119.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">สามชั้นทอดน้ำปลา</Link></h5></div><div className="price"><span>129.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เฟรนช์ฟรายส์</Link></h5></div><div className="price"><span>79.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวเกรียบทอด</Link></h5></div><div className="price"><span>79.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ถั่วทอด</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เม็ดมะม่วงหิมพานต์ทอด</Link></h5></div><div className="price"><span>99.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Pause Snack</Link><span className="s-info">แนะนำ</span></h5></div><div className="price"><span>179.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูผัด</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ผัดผักบุ้งไฟแดง</Link></h5></div><div className="price"><span>49.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">คะน้าน้ำมันหอย</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">คะน้าหมูกรอบ</Link></h5></div><div className="price"><span>109.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ผัดผักรวมมิตร</Link></h5></div><div className="price"><span>79.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">กะเพราหมูสับ/หมึก/กุ้ง</Link></h5></div><div className="price"><span>99.-/129.-</span></div></div>      
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูส้มตำ</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ตำไทย</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ตำลาว</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ตำถั่ว</Link></h5></div><div className="price"><span>59.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ตำแตง</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เมนูอาหารจานเดียว</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวไข่เจียว/หมูสับ</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวผัด</Link></h5></div><div className="price"><span>69.-/79.-</span></div></div>
                                    <div className="text desc"><Link to="#"><span>หมู/หมึก/กุ้ง</span></Link></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวผัดใหญ่</Link></h5></div><div className="price"><span>149.-/159.-</span></div></div> 
                                    <div className="text desc"><Link to="#"><span>หมู/หมึก/กุ้ง</span></Link></div>  
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวทอดกระเทียม</Link></h5></div><div className="price"><span>69.-/99.-</span></div></div>
                                    <div className="text desc"><Link to="#"><span>หมู/หมึก/กุ้ง (เล็ก/ใหญ่)</span></Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เครื่องเคียง</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ไข่ต้ม</Link></h5></div><div className="price"><span>15.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ไข่ดาว</Link></h5></div><div className="price"><span>15.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ไข่เจียว</Link></h5></div><div className="price"><span>15.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ข้าวสวย ถ้วย/โถ</Link></h5></div><div className="price"><span>15.-/59.-</span></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="menu-one">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Jaew Hon HOTPOT menu</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูแจ่วฮ้อน</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menuthree} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>รายการชุดรวม</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดแจ่วฮ้อนรวม (หมู,เนื้อ,ทะเล)</Link></h5></div><div className="price"><span>259.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดแจ่วฮ้อนรวม (หมู,เนื้อ)</Link><span className="s-info">แนะนำ</span></h5></div><div className="price"><span>229.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดแจ่วฮ้อนหมู</Link></h5></div><div className="price"><span>129.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดแจ่วฮ้อนเนื้อ</Link></h5></div><div className="price"><span>159.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดแจ่วฮ้อนทะเล</Link></h5></div><div className="price"><span>199.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>รายการชุดแยก</h2></span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">หมูรวม</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เนื้อรวม</Link></h5></div><div className="price"><span>109.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">สไบนาง</Link></h5></div><div className="price"><span>25.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ตับ</Link></h5></div><div className="price"><span>49.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">กุ้ง</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">หมึก</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ปลา</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">วุ้นเส้น</Link></h5></div><div className="price"><span>15.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>รายการเครื่องเคียง</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ผักสด</Link></h5></div><div className="price"><span>49.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ไข่ไก่</Link></h5></div><div className="price"><span>15.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">วุ้นเส้น</Link></h5></div><div className="price"><span>15.-</span></div></div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chef-selection">
         
                <div className="auto-container">
                <div className="title-box centered">

                        <div className="subtitle"><span>Promotion Set!</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>โปรโมชั่นเซ็ตอาหาร!</h2>
                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img9} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Promotion Set!</span></div>
                                        <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหาร</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>SET A</h3>
                                        <div className="text">แจ่วฮ้อนหมู ยำวุ้นเส้น ปีกไก่ทอด</div>
                                        <div className="text">และฟรี! น้ำแข็ง 1 ถัง!</div>
                                    </div>
                                    <div className="price"><span className="old">342.-</span> <span className="new">319.-</span></div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            
            <section className="chef-selection">
         
                <div className="auto-container">
                <div className="title-box centered">
                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img10} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Promotion Set!</span></div>
                                        <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหาร</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>SET B</h3>
                                        <div className="text">ข้าวผัดกะเพราหมูสับไข่ดาว หมูมะนาว</div>
                                        <div className="text">และฟรี! น้ำแข็ง 1 ถัง!</div>
                                    </div>
                                    <div className="price"><span className="old">198.-</span> <span className="new">179.-</span></div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section className="chef-selection">
         
                <div className="auto-container">
                <div className="title-box centered">
                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img11} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Promotion Set!</span></div>
                                        <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหาร</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>SET C</h3>
                                        <div className="text">ข้าวผัดหมูเล็ก หมูสามชั้นทอดน้ำปลา ยำถั่วพู</div>
                                        <div className="text">และฟรี! น้ำแข็ง 1 ถัง!</div>
                                    </div>
                                    <div className="price"><span className="old">342.-</span> <span className="new">325.-</span></div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section className="chef-selection">
         
         <div className="auto-container">
         <div className="title-box centered">
             </div>
             <div className="outer-container">
                 
                 <div className="row clearfix">
                     

                     <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                         <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                             <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                             </div>
                             <div className="image"><img src={img12} alt="" /></div>
                         </div>
                     </div>

                     <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                         <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                             <div className="title-box">
                                 <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                 <div className="subtitle"><span>Promotion Set!</span></div>
                                 <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหาร</span></div>
                                 <div className="pattern-image">
                                     <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                 </div>

                                 <h3>SET D</h3>
                                 <div className="text">แจ่วฮ้อนหมู ชุดหมูรวม ยำวุ้นเส้น</div>
                                 <div className="text">และฟรี! น้ำแข็ง 1 ถัง!</div>
                             </div>
                             <div className="price"><span className="old">292.-</span> <span className="new">275.-</span></div>
                             
                         </div>
                     </div>

                 </div>
             </div>
         </div>

            </section>

            <section className="menu-one">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Signature menu</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูแนะนำ</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menufour} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ซาบซ่าเสียวขาน้อย</Link><span className="s-info">5 แถม 1</span></h5></div><div className="price"><span>49.-</span></div></div>
                                        <div className="text desc"><Link to="#">เลือกรสชาติได้ บลูฮาวาย สตอร์เบอร์รี่ บลูเบอร์รี่ ลิ้นจี่ สับปะรด แอปเปิ้ลเขียว </Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">วงล้อหรรษา</Link></h5></div><div className="price"><span>159.-</span></div></div>
                                        <div className="text desc"><Link to="#">ให้โชคชะตานำพา</Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าปั่นถัง </Link></h5></div><div className="price"><span>89.-</span></div></div>
                                        <div className="text desc"><Link to="#">เลือกรสชาติได้ บลูฮาวาย สตอร์เบอร์รี่ บลูเบอร์รี่ ลิ้นจี่ สับปะรด แอปเปิ้ลเขียว </Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าปั่นปักถัง</Link></h5></div><div className="price"><span>189.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>ช้าง ลีโอ สิงห์ </span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าปั่นปักถัง</Link></h5></div><div className="price"><span>199.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>สเมอร์นอฟ (คลาสสิก, พิงค์ เลม่อน) </span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าปั่นปักถัง</Link></h5></div><div className="price"><span>199.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>โซจู (สตอเบอร์รี่, พีช, องุ่น) </span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าปั่นปักถัง</Link></h5></div><div className="price"><span>249.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>โฮการ์เด้น </span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าไซริ่งค์</Link></h5></div><div className="price"><span>159.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>เลือกรสชาติได้ บลูฮาวาย สตอร์เบอร์รี่ บลูเบอร์รี่ ลิ้นจี่ สับปะรด แอปเปิ้ลเขียว </span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">เหล้าหลอด</Link></h5></div><div className="price"><span>159.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>เลือกรสชาติได้ บลูฮาวาย สตอร์เบอร์รี่ บลูเบอร์รี่ ลิ้นจี่ สับปะรด แอปเปิ้ลเขียว </span></Link></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="menu-one">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Drink menu</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูเครื่องดื่ม</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menutwo} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เบียร์🍻</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🐘ช้าง 3 แถม 1</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>259.-</span></div></div>
                                        <div className="text desc"><Link to="#">ฟรี!น้ำแข็ง 1 ถัง!</Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🐯ลีโอ  3 แถม 1</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>259.-</span></div></div>
                                        <div className="text desc"><Link to="#">ฟรี!น้ำแข็ง 1 ถัง!</Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🦁สิงห์  3 แถม 1</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>269.-</span></div></div>
                                        <div className="text desc"><Link to="#">ฟรี!น้ำแข็ง 1 ถัง!</Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">⭐️ไฮเนเก้น 3 แถม 1</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>389.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>ฟรี!น้ำแข็ง 1 ถัง!</span></Link></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🐘ช้าง ขวดละ</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🐯ลีโอ ขวดละ</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🦁สิงห์ ขวดละ</Link></h5></div><div className="price"><span>79.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">⭐️ไฮเนเก้น ขวดละ</Link></h5></div><div className="price"><span>99.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>เหล้า🥃</h2></span></div></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃แสงโสม กลม</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>449.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>มิกเซอร์ 5 ขวด ฟรี!น้ำแข็ง 1 ถัง!</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃หงส์ทอง กลม</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>389.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>มิกเซอร์ 5 ขวด ฟรี!น้ำแข็ง 1 ถัง!</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃เบลนด์ ซิกเนเจอร์</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>479.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>มิกเซอร์ 5 ขวด ฟรี!น้ำแข็ง 1 ถัง!</span></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃เมอริเดียน</Link><span className="s-info">โปรฯ</span></h5></div><div className="price"><span>679.-</span></div></div>
                                        <div className="text desc"><Link to="#"><span>มิกเซอร์ 5 ขวด ฟรี!น้ำแข็ง 1 ถัง!</span></Link></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃SILVER WOLF</Link></h5></div><div className="price"><span>199.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃แสงโสม กลม</Link></h5></div><div className="price"><span>349.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃หงส์ทอง กลม</Link></h5></div><div className="price"><span>289.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃เบลนด์ ซิกเนเจอร์</Link></h5></div><div className="price"><span>379.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃เมอริเดียน</Link></h5></div><div className="price"><span>589.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥃รีเจนซี่ แบน</Link></h5></div><div className="price"><span>450.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>ไวน์คูลลิ่ง🍷</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍷Spy Red</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍷Spy Classic</Link></h5></div><div className="price"><span>59.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍷Smirnoff Pink lemon</Link></h5></div><div className="price"><span>79.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍷Smirnoff Classic</Link></h5></div><div className="price"><span>79.-</span></div></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>โซจูคอมเบ🍶</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍶สตอเบอร์รี่</Link></h5></div><div className="price"><span>119.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍶พีช</Link></h5></div><div className="price"><span>119.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🍶องุ่น</Link></h5></div><div className="price"><span>119.-</span></div></div>   
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="title-box centered">
                                    <div className="subtitle"><span><h2>มิกเซอร์🥤</h2></span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤น้ำเปล่า</Link></h5></div><div className="price"><span>20.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤แปปซี่ขวดใหญ่</Link></h5></div><div className="price"><span>40.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤โค้ก/แปปซี่เล็ก</Link></h5></div><div className="price"><span>20.-</span></div></div>   
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤ลิปตัน/โออิชิ</Link></h5></div><div className="price"><span>20.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤แฟนต้า</Link></h5></div><div className="price"><span>20.-</span></div></div>
                                    <div className="text desc"><Link to="#"><span>(แดง,เขียว,ส้ม)</span></Link></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤ชเวปส์</Link></h5></div><div className="price"><span>25.-</span></div></div>
                                    <div className="text desc"><Link to="#"><span>(มะนาวโซดา, ราสพ์เบอร์รี่, บลูเบอร์รี่)</span></Link></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🥤โซดา</Link></h5></div><div className="price"><span>20.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🧊น้ำแข็ง</Link></h5></div><div className="price"><span>20.-</span></div></div>
                                    <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">🧊น้ำแข็งบุฟเฟ่ต์</Link></h5></div><div className="price"><span>69.-</span></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="chef-selection">
            <div className="auto-container">
                <div className="title-box centered">
                <div className="subtitle"><span>Promotion Set!</span></div>

                    <div className="pattern-image">
                        <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                    </div>

                    <h2>โปรโมชั่นเซ็ตอาหารและเครื่องดื่ม!</h2>
                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img13} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Promotion Set!</span></div>
                                        <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหารและเครื่องดื่ม!</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>SET A</h3>
                                        <div className="text">ชุดแจ่วฮ้อนหมู เอ็นไก่ทอด ยำวุ้นเส้น </div>
                                        <div className="text">เบียร์🍻 3 แถม 1!</div>
                                        <div className="text">ฟรี! น้ำแข็ง 1 ถัง! ในราคาพิเศษ!</div>
                                    </div>
                                    <div className="price"><span className="old">610.-</span> <span className="new">586.-</span></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <section className="chef-selection">
         
                <div className="auto-container">
                <div className="title-box centered">

                    </div>
                    <div className="outer-container">
                        
                        <div className="row clearfix">
                            

                            <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                                <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                                    </div>
                                    <div className="image"><img src={img14} alt="" /></div>
                                </div>
                            </div>

                            <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                    <div className="title-box">
                                        <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                        <div className="subtitle"><span>Promotion Set!</span></div>
                                        <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหารและเครื่องดื่ม!</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>

                                        <h3>SET B</h3>
                                        <div className="text">หมูมะนาว หรือ ยำหมูยอ </div>
                                        <div className="text">ข้าวเกรียบกุ้ง หรือ เฟรนช์ฟรายส์</div>
                                        <div className="text">เบียร์🍻 3 แถม 1!</div>
                                        <div className="text">ฟรี! น้ำแข็ง 1 ถัง! ในราคาพิเศษ!</div>
                                    </div>
                                    <div className="price"><span className="old">451.-</span> <span className="new">437.-</span></div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section> 

            <section className="chef-selection">
         
         <div className="auto-container">
         <div className="title-box centered">

             </div>
             <div className="outer-container">
                 
                 <div className="row clearfix">
                     

                     <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12 d-md-block">  
                         <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                             <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}>

                             </div>
                             <div className="image"><img src={img15} alt="" /></div>
                         </div>
                     </div>

                     <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                         <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                             <div className="title-box">
                                 <span className="badge-icon"><img src={badgeone} alt="" title="" /></span>
                                 <div className="subtitle"><span>Promotion Set!</span></div>
                                 <div className="subtitle"><span>โปรโมชั่นเซ็ตอาหารและเครื่องดื่ม!</span></div>
                                 <div className="pattern-image">
                                     <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                 </div>

                                 <h3>SET C</h3>
                                 <div className="text">Pause Snack หงษ์ทอง 1 กลม </div>

                                 <div className="text">มิกเซอร์ 5 ขวด ฟรี! น้ำแข็ง 1 ถัง! ในราคาพิเศษ!</div>
                             </div>
                             <div className="price"><span className="old">568.-</span> <span className="new">555.-</span></div>
                             
                         </div>
                     </div>

                 </div>
             </div>
         </div>

     </section> 
        </>

    )
}

export default Startermenu