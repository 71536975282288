import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/resource/sidebar-logo.png'
import logonew from '../../assets/images/logo.png'
//import imgone from '../../assets/images/resource/menu-image-1.jpg'
//import imgtwo from '../../assets/images/resource/menu-image-2.jpg'
//import Imgthree from '../../assets/images/resource/menu-image-3.jpg'
//import Imgfour from '../../assets/images/resource/menu-image-4.jpg'


function Main() {

    const [active, setActive] = useState();
    //const [show, setShow] = useState();
    //const [menu, setMenu] = useState();
    //const [page, setPage] = useState();
    //const [drop, setDrop] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);
    
    const handleScroll=() => {
        
        const offset=window.scrollY;
        setlastscrool(offset);
        
        if(offset > 1000 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 1000 ){
          setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }
        
        else{
          setScrolled(false);
          setNav(true);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)
      })

      
    return (

        <>

        {active && 
            <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }

                <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
                    <div className="inner-box">
                        <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                        <div className="logo-box"><Link to="/" title="Delici - Restaurants HTML Template"><img src={logo} alt="" title="Delici - Restaurants HTML Template" />
                        </Link></div>

                        <div className="side-menu">
                            <ul className="navigation clearfix">
                                <li><Link to="/">หน้าแรก</Link></li>
                                <li><Link to="/menu">เมนูอาหารและเครื่องดื่ม</Link></li>
                                <li><a href="https://www.facebook.com/profile.php?id=100086758152148" target="_blank" rel="noreferrer">แฟนเพจเฟสบุ๊ค พอส คาเฟ่</a></li>
                                <li><a href="https://m.me/110791831815834" target="_blank" rel="noreferrer">ติดต่อเรา</a></li>
                            </ul>
                        </div>

                        <h2>ที่อยู่</h2>
                        <ul className="info">
                            <li>ทางหลวงหมายเลข 225, เลขที่ 309/9, หมู่ที่ 5, ตำบล ชีลอง, อำเภอ เมือง, <br /> จังหวัด ชัยภูมิ 36000, ประเทศไทย </li>
                            <li><a href="https://maps.app.goo.gl/sTdZnAhdJgHxxiQh8?g_st=ic" target="_blank" rel="noreferrer"><i className="icon far fa-map-marker-alt"></i> พิกัดร้านบน Google Maps</a></li>
                            <li>เปิดให้บริการ : 18:00-24:00 น.</li>
                        </ul>
                        <div className="separator"><span></span></div>
                        <div className="booking-info">
                            <div className="bk-title">สำรองจองโต๊ะ</div>
                            <li><a href="https://m.me/110791831815834" target="_blank" rel="noreferrer"><i className="icon far fa-envelope"></i> ผ่านแฟนเพจเฟสบุ๊ค พอส คาเฟ่</a></li>
                            <li><a href="tel:+66928351562"><i className="icon far fa-phone"></i> +66 92 835 1562 </a></li>
                        </div>

                    </div>
                </section>
            {Nav && 
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                    <li><a href="https://maps.app.goo.gl/sTdZnAhdJgHxxiQh8?g_st=ic" target="_blank" rel="noreferrer"><i className="icon far fa-map-marker-alt"></i> พิกัดร้านบน Google Maps</a></li>
                                        <li><i className="icon far fa-clock"></i> เปิดให้บริการ : 18:00-24:00 น.</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                    <li><a href="tel:+66928351562"><i className="icon far fa-phone"></i> +66 92 835 1562 </a></li>
                                        <li><a href="https://www.facebook.com/profile.php?id=100086758152148" target="_blank" rel="noreferrer"><i className="icon far fa-envelope"></i> FANPAGE Pause Cafe</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="header-upper header-fixed" >
                        <div className="auto-container">
                            <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                                <div className="links-box clearfix">
                                    <div className="nav-toggler">
                                        <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="logo-box">
                                    <div className="logo"><Link to="/" title="Delici - Restaurants React Template"><img src={logonew} alt="" title="Delici - Restaurants HTML Template" /></Link></div>
                                </div>

                                <div className="link link-btn">
                                    <a href="https://m.me/110791831815834" target="_blank" rel="noreferrer" className="theme-btn btn-style-one clearfix"> 
                                        <span className="btn-wrap">
                                            <span className="text-one">สำรองจองโต๊ะ</span>
                                            <span className="text-two">สำรองจองโต๊ะ</span>
                                        </span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </header> }
            
        </>

    )
}

export default Main