import React from 'react'
import { Link } from 'react-router-dom';
import bg19 from '../../../assets/images/background/bg-19.png'
import bg20 from '../../../assets/images/background/bg-20.png'

import menuimg5 from '../../../assets/images/resource/menu-image-5.png'
import menuimg6 from '../../../assets/images/resource/menu-image-6.png'
import menuimg7 from '../../../assets/images/resource/menu-image-7.png'
import menuimg8 from '../../../assets/images/resource/menu-image-8.png'
import menuimg9 from '../../../assets/images/resource/menu-image-9.png'
import menuimg10 from '../../../assets/images/resource/menu-image-10.png'



function Appetizers() {
    return (
        <>
            <section className="menu-two">

                <div className="right-bg"><img src={bg19} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>STARTER MENU</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูเรียกน้ำย่อย(Appetizers)</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg5} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">เอ็นไก่ทอด</Link></h5></div><div className="price"><span>89.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เอ็นไก่ทอดกรอบสูตรจากทางร้าน</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ถั่ว/เม็ดมะม่วงทอด </Link></h5></div><div className="price"><span>49/69฿</span></div></div>
                                        <div className="text desc"><Link href="#">ถั่ว/เม็ดมะม่วงทอดโรยเกลือกับแกล้มคลาสิค</Link></div>
                                    </div>

                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ข้าวเกรียบทอด</Link></h5></div><div className="price"><span>69.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ข้าวเกรียบทอดเสริฟพร้อมน้ำพริกเผา</Link></div>
                                    </div>

                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg7} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">สามชั้นทอดน้ำปลา</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">สามชั้นทอดน้ำปลากรอบๆเสริฟพร้องซอสพริกและน้ําจิ้มซีฟู๊ด</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ปูอัดวาซาบิ</Link></h5></div><div className="price"><span>89.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ปูอัดเสริฟพร้อมโชยุและวาซาบิ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ไก่ทอดน้ำปลา</Link></h5></div><div className="price"><span>119.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ไก่ทอดน้ำปลากรอบๆเสริฟพร้อมน้ำจิ้มไก่</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ไก่ทอดเกลือ</Link></h5></div><div className="price"><span>119.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ไก่ทอดเกลือสูตรเด็ดเสริฟพร้อมน้ำจิ้มไก่</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg10} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">เฟรนซ์ฟรายส์</Link></h5></div><div className="price"><span>49.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เฟรนซ์ฟรายส์ทอดกรอบเสริฟพร้อมซอสมะเขือเทศและมาโยเนส</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            


            <section className="menu-two">

                <div className="left-bg"><img src={bg20} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>fresh & hygine</span></div>

                        <div className="pattern-image">

                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />

                        </div>

                        <h2>เมนูหลัก(Main Dishes)</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg5} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ต้มเล้งแซ่บ<span className="s-info">ถ้วย/หม้อไฟ</span></Link></h5></div><div className="price"><span>99/159฿</span></div></div>
                                        <div className="text desc"><Link href="#">ต้มเล้งแซ่บมีทั้งแบบถ้วยและหม้อไฟ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดผักบุ้งไฟแดง/เพิ่มหมูกรอบ <span className="s-info">3แถม1</span></Link></h5></div><div className="price"><span>49/69฿</span></div></div>
                                        <div className="text desc"><Link href="#">ผัดผักบุ้งไฟแดงสูตรเด็ดจากทางร้าน</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ข้าวผัดกุ้ง/หมึก/ปู <span className="s-info">S/M/XL</span></Link></h5></div><div className="price"><span>69/119/169฿</span></div></div>
                                        <div className="text desc"><Link href="#">ข้าวผัดสามารถเลือกได้ทั้งกุ้งปลาหมึกและปูเสริฟพร้อมพริกน้ำปลาและมะนาวเสี้ยว</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดผักรวมหมู/ไก่ <span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>49/89฿</span></div></div>
                                        <div className="text desc"><Link href="#">ผัดผักหมูหรือไก่สามารถเลือกได้ทั้งราดข้าวและกับข้าว</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดผักรวมกุ้ง/หมึก<span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>69/129฿</span></div></div>
                                        <div className="text desc"><Link href="#">ผัดผักกุ้งหรือหมึกสามารถเลือกได้ทั้งราดข้าวและกับข้าว</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">กะหล่ำผัดน้ำปลา/เพิ่มหมูสามชั้น</Link></h5></div><div className="price"><span>49/69฿</span></div></div>
                                        <div className="text desc"><Link href="#">กะหล่ำผัดน้ำปลาสามารถเพิ่มหมูสามชั้นได้</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ข้าวผัดไข่/หมู<span className="s-info">S/M/XL</span></Link></h5></div><div className="price"><span>49/99/149฿</span></div></div>
                                        <div className="text desc"><Link href="#">ข้าวผัดไข่หรือหมูเสริฟพร้อมพริกน้ำปลาและมะนาวเสี้ยว</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">เสต็กหมู<span className="s-info">Commingsoon</span></Link></h5></div><div className="price"><span>XX.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เสต็กหมูเสริฟพร้อมเฟรนซ์ฟรายส์และซอสต่างๆ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">เสต็กเนื้อ<span className="s-info">Commingsoon</span></Link></h5></div><div className="price"><span>XX.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เสต็กเนื้อเสริฟพร้อมเฟรนซ์ฟรายส์และซอสต่างๆ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg7} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">เสต็กเนื้อดรายเอจ<span className="s-info">Commingsoon</span></Link></h5></div><div className="price"><span>XX.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เสต็กเนื้อดรายเอจเสริฟพร้อมเฟรนซ์ฟรายส์และซอสต่างๆ</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดกระเพราหมู/หมูกรอบ/ไก่ <span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>49/89฿</span></div></div>
                                        <div className="text desc"><Link href="#">เมนูง่ายๆเสริฟพร้อมพริกน้ำปลา</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดกระเพราปู/กุ้ง/หมึก<span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>69/129฿</span></div></div>
                                        <div className="text desc"><Link href="#">กระเพราทะเลเสริฟพร้อมพริกน้ำปลา</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ไข่เจียว/ผัก <span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>35/69฿</span></div></div>
                                        <div className="text desc"><Link href="#">ไข่เจียว+ผักสูตรเด็ดจากเจ้าของร้านเสริฟพร้อมซอสพริก</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ไข่เจียวหมูสับ <span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>39/89฿</span></div></div>
                                        <div className="text desc"><Link href="#">ไข่เจียวหมูสับสูตรเจ้าของร้านเสริฟพร้อมซอสพริก</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ไข่เจียวกุ้ง/ปู <span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>69/119฿</span></div></div>
                                        <div className="text desc"><Link href="#">ไข่เจียวกุ้ง/ปู สูตรเจ้าของร้านเสริฟพร้อมซอสพริก</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ผัดกระเทียมหมู/ไก่<span className="s-info">ราดข้าว กับข้าว</span></Link></h5></div><div className="price"><span>49/89฿</span></div></div>
                                        <div className="text desc"><Link href="#">หมู/ไก่ ผัดกระเทียมเสริฟพร้อมซอสพริก</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ต้มยำหมูสามชั้น/ซี่โครง<span className="s-info">ถ้วย/หม้อไฟ</span></Link></h5></div><div className="price"><span>129/169฿</span></div></div>
                                        <div className="text desc"><Link href="#">ต้มยำหมูสามชั้น/ซี่โครง มีทั้งแบบถ้วยและหม้อไฟ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ต้มยำไก่/เอ็นไก่<span className="s-info">ถ้วย/หม้อไฟ</span></Link></h5></div><div className="price"><span>89/149฿</span></div></div>
                                        <div className="text desc"><Link href="#">ต้มยำไก่และต้มยำเอ็นไก่สูตรเด็ด</Link></div>
                                    </div>
                                </div>
                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg10} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ต้มยำปลา/กุ้ง/หมึก/รวมทะเล<span className="s-info">ถ้วย/หม้อไฟ</span></Link></h5></div><div className="price"><span>129/199฿</span></div></div>
                                        <div className="text desc"><Link href="#">ต้มยำเลือกได้ ปลาดอลลี่ กุ้ง ปลาหมึก</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="menu-two">

                <div className="right-bg"><img src={bg19} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>HOT&Spicy</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูยำ(Spicy Salad)</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg5} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำเอ็นไก่ทอด</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ยำเอ็นไก่ทอดกรอบๆรสชาติจี๊ดจ๊าด</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำเม็ดมะม่วงทอด</Link></h5></div><div className="price"><span>69.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ยำจี๊ดจ๊าดเม็ดมะม่วงกรุบกรอบ</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg6} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">หมูมะนาว</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">หมูมะนาวและน้ำราดรสเด็ด</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg7} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำวุ้นเส้น/ยำวุ้นเส้นหมูสับ</Link></h5></div><div className="price"><span>89.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">เมนูยำคาลสสิค</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg8} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำถั่วพลู<span className="s-info">HOTHIT</span></Link></h5></div><div className="price"><span>149.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ยำถั่วพลูสูตรเด็ดจากทางร้าน</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg9} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำกุ้ง/หมึก/รวมทะเล</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">ยำทะเลและน้ำยำสูตรจากทางร้าน</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="dish-image"><Link href="#"><img src={menuimg10} alt="" /></Link></div>
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">ยำหมูยอ</Link></h5></div><div className="price"><span>89.00฿</span></div></div>
                                        <div className="text desc"><Link href="#">หมูยออุบลและน้ำยำสูตรจากทางร้าน</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            
        </> 
        
    )
}

export default Appetizers