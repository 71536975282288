import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/image-2.jpg'
import OwlCarousel from 'react-owl-carousel';
import author1 from '../../assets/images/resource/author-thumb-1.jpg'
import author2 from '../../assets/images/resource/author-thumb-2.jpg'
import author3 from '../../assets/images/resource/author-thumb-3.jpg'


function Contact() {
    const [flag, setflag] = useState(false)
    const slider1 = useRef()
    const slider2 = useRef()
    const duration = 500;
    const syncPosition = (e) => {
        if (!flag) {
            setflag(false)
            if (slider2.current) {
                slider2.current.to(e.item.index, duration)
            }
            setflag(false)
        }
    }
    

    return (
        <>
            <div className="testimonials-section" >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <OwlCarousel className="testi-top owl-theme owl-carousel" ref={slider1} items={1} loop margin={10} nav autoplay onChange={(e) => syncPosition(e)}>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                            <div className="slide-item item">
                                <div className="slide-content">
                                    <div className="quotes">”</div>
                                    <div className="text quote-text">I wanted to thank you for inviting me down for that amazing dinner the other night. The food was extraordinary.</div>
                                </div>
                            </div>
                        </OwlCarousel>
                        <div className="separator"><span></span><span></span><span></span></div>
                        <div className="thumbs-carousel-box">
                            <OwlCarousel className="testi-thumbs owl-theme owl-carousel" ref={slider2} items={1} loop margin={10} nav autoplay>
                                <div className="slide-item item">
                                    <div className="image">

                                        <img src={author1} alt="" />

                                    </div>

                                    <div className="auth-title">Sam Jhonson</div>
                                </div>
                                <div className="slide-item item">
                                    <div className="image">

                                        <img src={author2} alt=""/></div>

                                    <div className="auth-title">Ian Botham</div>

                                </div>
                                <div className="slide-item item">
                                    <div className="image"><img src={author3} alt="" /></div>
                                    <div className="auth-title">Dan Bitson</div>
                                </div>
                                <div className="slide-item item">
                                    <div className="image"><img src={author1} alt="" /></div>
                                    <div className="auth-title">Sam Jhonson</div>
                                </div>
                                <div className="slide-item item">
                                    <div className="image"><img src={author2} alt="" /></div>
                                    <div className="auth-title">Ian Botham</div>
                                </div>
                                <div className="slide-item item">
                                    <div className="image"><img src={author3} alt="" /></div>
                                    <div className="auth-title">Dan Bitson</div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact