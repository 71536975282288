import React from 'react'
import Bredcom from '../Bredcrumb/Main'
import Book from '../../components/Menhome/Book'


function Main() {
  return (
    <>
      <Bredcom
        subtitle="การจอง"
        title="สำรองโต๊ะ"
      />
      
      <Book />
    </>
  )
}

export default Main