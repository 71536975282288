import React from 'react'
import { Link } from 'react-router-dom'
import bg22 from '../../../assets/images/background/bg-22.png'
import bg23 from '../../../assets/images/background/bg-23.png'
import menusdesertone from '../../../assets/images/resource/menus-desert.jpg'
import menusdrinksone from '../../../assets/images/resource/menus-drinks.jpg'

function Offers() {
    return (

        <>
            <section className="menu-one">
                <div className="left-bg"><img src={bg22} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span> warm & tasty</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูแจ๋วฮ้อน(TH HotPot)</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menusdesertone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนหมู</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        <div className="text desc"><Link to="#">รวมไปด้วยแจ๋วฮ้อนหมูชุดผัก+วุ้นเส้น+ไข่ไก่</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนเนื้อ</Link></h5></div><div className="price"><span>169.00฿</span></div></div>
                                        <div className="text desc"><Link to="#">รวมไปด้วยแจ๋วฮ้อนเนื้อชุดผัก+วุ้นเส้น+ไข่ไก่</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนทะเล</Link></h5></div><div className="price"><span>189.00฿</span></div></div>
                                        <div className="text desc"><Link to="#">รวมไปด้วยแจ๋วฮ้อนทะเลชุดผัก+วุ้นเส้น+ไข่ไก่</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนเฉพาะหมู</Link></h5></div><div className="price"><span>89.00฿</span></div></div>
                                        
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนเฉพาะเนื้อ</Link></h5></div><div className="price"><span>129.00฿</span></div></div>
                                        
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">แจ๋วฮ้อนเฉพาะกุ้ง/หมึก</Link></h5></div><div className="price"><span>149.00฿</span></div></div>
                                        
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ชุดผัก</Link></h5></div><div className="price"><span>59.00฿</span></div></div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="menu-one alternate">

                <div className="right-bg-2"><img src={bg23} alt="" title="" /></div>
                <div className="auto-container">
                    
                    <div className="title-box centered">
                        <div className="subtitle"><span>hot & cold</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>เมนูเครื่องดื่ม(Drinks Menu)</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menusdrinksone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">ซาบซ่าเสียวขาน้อย<span className="s-info">80ml/200ml</span></Link></h5></div><div className="price"><span>49/129฿</span></div></div>
                                        <div className="text desc"><Link to="#">เมนูเครื่องดื่ม</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Sunrises <span className="s-info">SEASONAL(ตามฤดูกาล)</span></Link></h5></div><div className="price"><span>159.00B</span></div></div>
                                        <div className="text desc"><Link to="#">Vegetables, cheeses, ground meats, tomato sauce, seasonings and spices</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Butternut Pumpkin</Link></h5></div><div className="price"><span>$10.00</span></div></div>
                                        <div className="text desc"><Link to="#">Typesetting industry lorem Lorem Ipsum is simply dummy text of the priand.</Link></div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">Tokusen Wagyu <span className="s-info">NEW</span></Link></h5></div><div className="price"><span>$39.00</span></div></div>
                                        <div className="text desc"><Link to="#">Vegetables, cheeses, ground meats, tomato sauce, seasonings and spices.</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </>


    )
}

export default Offers